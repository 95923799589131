html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
}

.app-content {
    height: 100%;
}

.app-page {
    min-height: 100%;
}

.app-header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    text-align: center;
}

.mbsc-lv-fixed-header {
    top: 48px !important;
    xtop: 92px !important;
}
.mbsc-page .mbsc-lv-fixed-header h4 {
    margin: 0 !important;
    font-size: 1em !important;
}

.mbsc-ms-top {
    xtop: 48px !important;
}

.mbsc-page .app-header h3 {
    margin: 0;
    padding: 0 12px;
    font-size: 20px;
}

.app-tab {
    padding-top: 48px;
    padding-bottom: 64px;
}

.mbsc-lv-img {
    max-height: 5em !important;
    max-width: 5em !important;
}

.mbsc-lv-img-large {
    max-height: 10em !important;
    max-width: 10em !important;
    left: 5em !important;
}

.react-international-phone-input-container {
    padding: 0 !important;
}
.react-international-phone-input-label {
    padding: 8px 16px 16px !important;
}
.react-international-phone-input-span {
    padding-bottom: .3125em !important;
    font-size: .8125em !important;
}
.react-international-phone-country-selector {
    display: none;
}

/* Google Pay overrides to prevent mobiscroll "enhancing" the button */

.google-pay-button-container {
    display: inline-block;
    width: 100%;
}
.gpay-button-fill, .gpay-button-fill > .gpay-button.white, .gpay-button-fill > .gpay-button.black {
    width: 100%;
    height: inherit;
}
.gpay-button.black.pay.en {
    background-image: url(https://www.gstatic.com/instantbuy/svg/dark/pay/en.svg);
}
.gpay-button.black.checkout.en {
    background-image: url(https://www.gstatic.com/instantbuy/svg/dark/checkout/en.svg);
}
.gpay-button.pay.en {
    min-width: 150px;
}
.gpay-button.checkout.en {
    min-width: 201px;
}
.gpay-button-fill > .gpay-button.donate, .gpay-button-fill > .gpay-button.book, .gpay-button-fill > .gpay-button.checkout, .gpay-button-fill > .gpay-button.order, .gpay-button-fill > .gpay-button.pay, .gpay-button-fill > .gpay-button.subscribe {
    padding: 9px 15%;
}
.gpay-button.black {
    background-color: #000 !important;
    box-shadow: none;
}
.gpay-button {
    background-origin: content-box !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    border: 0px !important;
    margin: 0px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    height: 40px;
    min-height: 40px !important;
    padding: 12px 24px 10px;
    width: 240px;
}

/* MPO styles */

.sticky-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

@media (min-width: 991px) {
    .show-on-small-device {
        display: none;
    }
    .show-on-large-device {
        display: block;
    }
}

@media (max-width: 990px) {

    .show-on-small-device {
        display: block;
    }

    .show-on-large-device {
        display: none;
    }

}

.mpo-form-width-responsive {
    max-width: 1200px;
    margin: auto;
}

.mbsc-form.mpo-form-width-sm {
    max-width: 540px;
    margin: auto;
}

.mbsc-form.mpo-form-width-md {
    max-width: 720px;
    margin: auto;
}

.mbsc-form.mpo-form-width-lg {
    max-width: 960px;
    margin: auto;
}

.mbsc-form.mpo-form-width-xl {
    max-width: 1140px;
    margin: auto;
}

div.background-image {
    height: auto;
    min-height: 100%;
    min-width: 100%;
    background: no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute;
}

span.mpo-merchant-badge {
    top: -0.1em !important;
    right: 0 !important;
    font-size: 0.65em !important;
}

button.mpo-checkout-head {
    margin: .1em !important;
    font-size: 1.25em !important;
    padding: 0.5em !important;
}

span.mpo-checkout-head-badge {
    margin-left: 0 !important;
    font-size: 0.6em !important;
}

.mbsc-form-group-inset.mpo-checkout-form {
    margin: 1em !important;
}

.mbsc-form-group-inset.mpo-no-top-radius {
    border-top-left-radius: 0em !important;
    border-top-right-radius: 0em !important;
}

li h4.mpo-product-group-head {
    text-transform: none;
    font-size: 1.2em !important;
    font-weight: bold !important;
    margin: 0 !important;
}
li p.mpo-product-group-head-desc {
    text-transform: none;
    font-weight: normal !important;
    margin: 0 !important;
    font-size: .8em !important;
}

div.mbsc-product-list .mbsc-lv {
    background: none;
}

@media (min-width: 801px) {
    li.mpo-product-group-head-with-image-bg {
        border-style: solid !important;
        border-width: 3px !important;
        border-color: black !important;
        margin: 10px !important;
        background-position: center !important;
        color: #fff !important;
        padding-left: 1em !important;
        min-height: 250px !important;
        text-align: center !important;
    }
}

@media (max-width: 800px) {
    li.mpo-product-group-head-with-image-bg {
        border-style: solid !important;
        border-width: 3px !important;
        border-color: black !important;
        margin: 10px !important;
        background-position: center !important;
        color: #fff !important;
        padding-left: 1em !important;
        min-height: 150px !important;
        text-align: center !important;
    }
}

li.mpo-product-group-head-with-image-bg .mbsc-lv-arr.mbsc-lv-ic.mbsc-ic {
    color: #fff !important;
    display: none;
}
li.mpo-product-group-head-with-image-bg h4.mpo-product-group-head {
    font-size: 2em !important;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
li.mpo-product-group-head-with-image-bg p.mpo-product-group-head-desc {
    color: #fff !important;
    xbackground-color:rgba(0,0,0,0.3) !important;
    font-size: 1.1em !important;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
}

li.mpo-product-group-head-with-image-bg.mbsc-lv-item:before,
li.mpo-product-group-head-with-image-bg.mbsc-lv-item:after {
    border-top: none !important;
}

li.mpo-product-group-head-no-image.mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 1em !important;
}

li:not(:first-child).mpo-product-group-head-no-image.mbsc-lv-parent.mbsc-lv-img-left:before,
li:not(:last-child).mpo-product-group-head-no-image.mbsc-lv-parent.mbsc-lv-img-left:after {
    left: 1em;
}

.mbsc-material li.mpo-product-group-head-no-image {
    background: #fafafa !important;
}

li.mpo-product-item.mbsc-lv-item.mbsc-lv-img-left {
    min-height: 80px !important;
    padding-left: 5.5em !important;
}

li.mpo-product-item-large.mbsc-lv-item {
    margin: 10px !important;
    min-height: 80px !important;
}

li.mpo-product-item-large-reduce-padding.mbsc-lv-item {
    padding: 0.5em !important;
}

.mbsc-material li.mpo-product-item, .mbsc-material li.mpo-product-item-large {
    background: #fafafa !important;
}

li.mpo-product-item-large.mbsc-lv-item:before, li.mpo-product-item-large.mbsc-lv-item:after {
    border-style: none !important;
    border-width: 0px !important;
}
li.mpo-product-item-large.mbsc-lv-item.mbsc-lv-img-left {
    min-height: 160px !important;
    padding-left: 11em !important;
}

ul li span.mpo-product-price-bottom {
    position: absolute;
    bottom: 15px;
}

ul li span.mpo-product-price {
    padding-right: 60px;
    top: 35%;
    position: absolute;
    bottom: 0;
    right: 0;
}

ul li h3.mpo-product-head {
    padding-right: 8em;
}

ul li span.mpo-product-desc {
    padding-right: 8em !important;
}

ul li span.mpo-product-desc-full {
}

ul li span.mpo-product-dietary {
    font-size: 0.7em;
}

.merchant-card-simple {
    border-radius: 0 !important;
    border-style: solid !important;
    border-width: 3px !important;
    border-color: black !important;
    text-align: center !important;
    font-size: 0.8em !important;
}

.merchant-card-simple-inactive {
    background: #808080 !important;
}

.md-card-hero {
    position: absolute;
    color: #fff;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
}

.md-card-hero-title {
    color: #fff !important;
}

.md-card-hero-subtitle {
    color: #fff !important;
    font-weight: 500 !important;
}

.md-card-overlay {
    background-color:rgba(0,0,0,0.3) !important;
}

.md-logo-overlay {
    position: absolute;
    background-color:rgba(0,0,0,0.4) !important;
    text-align: center;
    width: 64px;
    color: #fff !important;
    font-size: small;
}

.md-card-tab-nav .mbsc-ms-item {
    padding: 0.1em !important;
    font-size: small;
}

.autocomplete-dropdown-container {
    background: #fff;
    color: #000;
}
.suggestion-item {
    padding: 0.5em 0.5em;
}
.suggestion-item-active {
    background: #d3d3d3;
}

.fb-login-btn-small {
    background: #3c5c9a !important;
    color: #fff !important;
}

.fb-login-btn {
    background: #3c5c9a !important;
    color: #fff !important;
    height: 38px;
    width: 290px;
}

.apple-login-btn {
    background: #000 !important;
    color: #fff !important;
}

.google-login-btn {
    background: #000 !important;
    color: #fff !important;
}

h5.mpo-promo-head {
    margin: 0.5em 0 !important;
}

/* Mobiscroll Theme */

.mbsc-mobiscroll .app-header {
    border-bottom: 1px solid #ccc;
    color: #000;
    background: #fff no-repeat center;
    background-size: 200px; 
}

/* FontAwesome Icons */
.mbsc-ic.icon {
    font-family: inherit;
    font-weight: normal;
}
.mbsc-ic.icon:before {
    font-family:'Font Awesome\ 5 Free', 'Font Awesome\ 5 Brands', 'Font Awesome 5 Pro';
}   
.mbsc-ic.icon.fas:before,
.mbsc-ic.icon.fa:before {
    font-weight: 900;
}

/* Fix bottomnav wrapping when using font awesome */
.mbsc-ms-more .mbsc-ms-item {
    display: block;
}

/* iOS Theme */

.mbsc-ios .app-header {
    border-bottom: 1px solid #ccc;
    color: #000;
    background: #fff no-repeat center;
    background-size: 200px; 
}

.mbsc-ios .app-header h1 {
    font-size: 17px;
}

.mbsc-ios .app-tab {
    padding-bottom: 56px;
}

.mbsc-ios .app-tab-home .mbsc-divider {
    margin-bottom: -1px;
}

.mbsc-ios.mbsc-snackbar .mbsc-snackbar-btn {
    color: #fff !important;
}

.mbsc-ios-dark .app-header {
    border-bottom: 1px solid #ccc;
    color: #fff;
    background: #000 no-repeat center;
    background-size: 200px; 
}


.mbsc-ios.mbsc-control-w:before {
    border-top: 0px !important;
}

.mbsc-ios.mbsc-fr-bottom .mbsc-fr-overlay, .mbsc-ios.mbsc-fr-top .mbsc-fr-overlay {
    background: rgba(0,0,0,0.2) !important;
}

/* Material Theme */

.mbsc-material .app-header {
    border-bottom: 1px solid #ccc;
    color: #000;
    background: #fff no-repeat center;
    background-size: 200px; 
}

.mbsc-material .app-tab {
    padding-bottom: 56px;
}

.mbsc-material-dark .app-header {
    border-bottom: 1px solid #ccc;
    color: #fff;
    background: #000 no-repeat center;
    background-size: 200px; 
}

/* Windows Theme */

.mbsc-windows .app-header {
    border-bottom: 1px solid #ccc;
    color: #000;
    background: #fff no-repeat center;
    background-size: 200px; 
}

.mbsc-windows-dark .app-header {
    border-bottom: 1px solid #ccc;
    color: #fff;
    background: #000 no-repeat center;
    background-size: 200px; 
}

.mbsc-windows .app-tab {
    padding-bottom: 64px;
}

.mbsc-windows .app-tab .mbsc-lv-cont .mbsc-lv .mbsc-lv-item {
    xpadding-left: 4.375em;
}

.mbsc-windows .app-tab .mbsc-lv-cont .mbsc-lv .mbsc-checkbox-box {
    left: 2.5em;
}

